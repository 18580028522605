import React, {useRef, useState} from "react"

import Layout from "../components/layout"
import PageHeader from '../components/pageHeader'
import PageTemplate from '../components/pageTemplate'
import {Blog} from '../components/sidebar'
import {NormalField, Select} from 'reactstrap-form-fields'
import axios from 'axios'

let options = [
  "Google Search Listing",
  "Google Ad Listing",
  "Facebook",
  "LinkedIn",
  "Word of mouth",
  "Other"
]

let Form = ({setFormSubmit}) => {
  const form = useRef(null);
  let handleSubmit = (e) => {
    if (e) e.preventDefault()
    let formData = new FormData(e.target)
    axios({
      method: 'post',
      url: '/',
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }).then(() => setFormSubmit(true))
  }

  return (
    <form
      ref={form}
      className="form"
      data-netlify="true"
      method="post"
      netlify-honeypot="bot-field"
      name="Download Ebook"
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="form-name" value="Download Ebook"/>
      <NormalField name="First Name"/>
      <NormalField name="Last Name"/>
      <NormalField name="Email" type="email"/>
      <NormalField name="Phone" type="number"/>
      <Select name="How you heard about us?" fieldType="normal" label="How did you hear about us?" options={options}/>
      <input className="btn btn-secondary" type="submit" value="DOWNLOAD EFLYER NOW"/>
    </form>
  )
}

let Content = () => {
  let [formSubmit, setFormSubmit] = useState(false)
  return (
    <>
      <h2>REGISTER BELOW FOR YOUR FREE EFLYER</h2>
      <img src="https://app-au.techsonthemove.com/wp-content/uploads/2018/11/banner-aus.jpg"/>
      {!formSubmit ? <div>
        <p>Register using the form below. Once you click submit the page will reload with the link to the eFlyer.</p>
        <h4>eFlyer Download</h4>
        <Form setFormSubmit={setFormSubmit}/>
      </div> :
      <p>Thanks, please <a href="http://app-au.techsonthemove.com/wp-content/uploads/2019/01/TOTM-E-flyer.pdf">click here</a> to download the eFlyer</p>}
    </>
  )
}

const NotFoundPage = ({location}) => (
  <Layout title="DOWNLOAD EFLYER">
    <PageHeader title="DOWNLOAD EFLYER"/>
    <PageTemplate Sidebar={Blog} location={location}>
      <Content/>
    </PageTemplate>
  </Layout>
)

export default NotFoundPage
